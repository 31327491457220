<template>
  <div
    role="alert"
    class="AiTooltip"
    :class="{
      [`AiTooltip--${variant}`]: Boolean(variant),
      [`AiTooltip--${background}`]: Boolean(background),
    }">
    <ai-icon
      :name="variant"
      :size="size"
      class="AiTooltip-icon"
      :class="{ [`AiTooltip-icon--${iconFill}`]: Boolean(iconFill) }" />

    <div class="AiTooltip-body">
      <ai-typo
        as="p"
        :variant="typoVariant"
        class="AiTooltip-label"
        :color="textColor">
        {{ label }}
      </ai-typo>

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SizeProp } from '../../atoms/AiIcon/AiIcon.vue';
import type { IconName } from '../../atoms/AiIcon/types';
import type { AiTypoVariant, TypoColor } from '../../atoms/AiTypo/types';

interface AiTooltipProps {
  variant: IconName;
  label: string;
  alignItem: 'flex-end' | 'flex-start' | 'center';
  background?: string;
  iconFill?: TypoColor;
  size?: SizeProp | number;
  textColor?: TypoColor;
  align?: 'left' | 'center';
}

withDefaults(defineProps<AiTooltipProps>(), {
  align: 'center',
  alignItem: 'flex-start',
  background: undefined,
  iconFill: undefined,
  textColor: undefined,
  size: 24,
});

const isSmallDevice = useMatchMediaQuery('tablet');

// TODO: to refactor, should never happen
const typoVariant = computed<AiTypoVariant>(() =>
  isSmallDevice.value ? 'legal-regular' : 'paragraph-04-regular',
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/mq';

.AiTooltip {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: constants.$inner-03 constants.$inner-04;

  @include mixins.allBackgroundColorsVariant();

  @media (mq.$from-medium) {
    align-items: v-bind(alignItem);
    justify-content: v-bind(align);
    padding: constants.$inner-03 constants.$padding-02;
  }

  &--inProcess {
    background: colors.$aqua-blue-100;
  }
}

.AiTooltip-icon {
  display: inline-block;
  margin-right: constants.$margin-01;

  @include mixins.allColorColorsVariant();
}

.AiTooltip-body {
  display: block;
}
</style>
