<template>
  <div
    class="AiInputWithSelector"
    :class="[
      {
        'AiInputWithSelector--disabled': disabled,
        'AiInputWithSelector--inverse': inverse,
        'AiInputWithSelector--filled': modelValue,
        'AiInputWithSelector--bordered': variant === 'bordered',
      },
    ]">
    <ai-typo
      v-if="title"
      class="AiInputWithSelector-title"
      as="span"
      variant="overline-02">
      {{ title }}
    </ai-typo>

    <div class="AiInputWithSelector-inputGroup">
      <div
        v-if="label || helper || message"
        class="AiInputWithSelector-labelGroup">
        <ai-typo
          v-if="label"
          class="AiInputWithSelector-label"
          as="label"
          variant="paragraph-04-bold">
          {{ label }}
        </ai-typo>
        <ai-typo
          v-if="helper"
          class="AiInputWithSelector-helper"
          as="label"
          variant="paragraph-04-regular">
          {{ helper }}
        </ai-typo>
        <ai-typo
          v-if="message"
          class="AiInputWithSelector-message"
          as="label"
          variant="legal-regular">
          {{ message }}
        </ai-typo>
      </div>

      <div class="AiInputWithSelector-input">
        <ai-selector-button
          ref="decrementButton"
          selector="minus"
          :disabled="!canDecrement"
          :label="
            $t('booking.bar.inputs.roomAndGuest.buttonControlMinus', {
              title: subtitle,
              item: label,
            })
          "
          :variant="inverse ? 'inverse' : 'positive'"
          @click="decrement" />
        <ai-typo
          class="AiInputWithSelector-inputResult"
          as="output"
          variant="paragraph-03-regular">
          <span>{{ modelValue }}</span>
          <span class="sr-only">{{ label }} {{ subtitle }}</span>
        </ai-typo>
        <ai-selector-button
          ref="incrementButton"
          :disabled="!canIncrement"
          :label="
            $t('booking.bar.inputs.roomAndGuest.buttonControlPlus', {
              title: subtitle,
              item: label,
            })
          "
          :variant="inverse ? 'inverse' : 'positive'"
          @click="increment" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface AiInputWithSelectorProps {
  disabled?: boolean;
  helper?: string;
  inverse?: boolean;
  label?: string;
  max?: number;
  message?: string;
  min?: number;
  modelValue: number;
  step?: number;
  title?: string;
  variant?: 'default' | 'bordered';
  subtitle?: string;
  incrementStep?: number;
  decrementStep?: number;
}

const props = withDefaults(defineProps<AiInputWithSelectorProps>(), {
  disabled: false,
  helper: undefined,
  inverse: false,
  label: undefined,
  max: undefined,
  message: undefined,
  min: undefined,
  step: 1,
  subtitle: undefined,
  title: undefined,
  variant: 'default',
  incrementStep: undefined,
  decrementStep: undefined,
});

type Emits = {
  (event: 'update:modelValue', value: number): void;
};

const emits = defineEmits<Emits>();

const incrementButton = ref<ComponentPublicInstance | null>(null);
const decrementButton = ref<ComponentPublicInstance | null>(null);

const currentValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const decrementStep = computed(() => props.decrementStep || props.step);
const incrementStep = computed(() => props.incrementStep || props.step);

const canDecrement = computed(() => {
  if (props.disabled) return false;

  if (typeof props.min === 'number') {
    return currentValue.value - decrementStep.value >= props.min;
  }

  return true;
});

const decrement = () => {
  if (!canDecrement.value) return;

  currentValue.value -= decrementStep.value;

  nextTick(() => {
    if (!canDecrement.value) {
      incrementButton.value?.$el.querySelector('button')?.focus();
    }
  });
};

const canIncrement = computed(() => {
  if (props.disabled) return false;

  if (typeof props.max === 'number') {
    return currentValue.value + incrementStep.value <= props.max;
  }

  return true;
});

const increment = () => {
  if (!canIncrement.value) return;

  currentValue.value += incrementStep.value;

  nextTick(() => {
    if (!canIncrement.value) {
      decrementButton.value?.$el.querySelector('button')?.focus();
    }
  });
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.AiInputWithSelector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: constants.$inner-01;
}

.AiInputWithSelector-inputGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: constants.$inner-04;
}

.AiInputWithSelector-labelGroup {
  display: flex;
  flex-direction: column;
}

.AiInputWithSelector-label,
.AiInputWithSelector-helper {
  color: colors.$stratos-600;
}

.AiInputWithSelector-message {
  margin-top: constants.$inner-01;
  color: colors.$red-700;
}

.AiInputWithSelector-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: constants.$inner-01;
  color: colors.$stratos-900;
}

.AiInputWithSelector-inputResult {
  min-width: 1ch;
  margin: 0 constants.$margin-00;
  text-align: center;
}

.AiInputWithSelector.AiInputWithSelector--filled {
  .AiInputWithSelector-title {
    color: colors.$stratos-500;
  }

  .AiInputWithSelector-label,
  .AiInputWithSelector-helper {
    color: colors.$stratos-900;
  }
}

.AiInputWithSelector.AiInputWithSelector--disabled {
  .AiInputWithSelector-title {
    color: colors.$neutral-400;
  }

  .AiInputWithSelector-label,
  .AiInputWithSelector-helper {
    color: colors.$neutral-600;
  }
}

.AiInputWithSelector--bordered {
  .AiInputWithSelector-inputResult {
    border: 1px solid colors.$gold-900;
    // Arbitrary values set in figma
    @include mixins.rem-fallback(min-width, 100);
    @include mixins.rem-fallback(height, 40);
    @include mixins.rem-fallback(line-height, 40);
  }
}

.AiInputWithSelector.AiInputWithSelector--inverse {
  .AiInputWithSelector-title,
  .AiInputWithSelector-inputResult {
    color: colors.$white;
  }

  .AiInputWithSelector-label,
  .AiInputWithSelector-helper {
    color: colors.$neutral-300;
  }

  .AiInputWithSelector-message {
    color: colors.$red-400;
  }

  &.AiInputWithSelector--filled {
    .AiInputWithSelector-title {
      color: colors.$neutral-400;
    }

    .AiInputWithSelector-label,
    .AiInputWithSelector-helper {
      color: colors.$white;
    }
  }

  &.AiInputWithSelector--disabled {
    .AiInputWithSelector-title {
      color: colors.$neutral-600;
    }

    .AiInputWithSelector-label,
    .AiInputWithSelector-helper,
    .AiInputWithSelector-inputResult {
      color: colors.$neutral-400;
    }
  }

  &.AiInputWithSelector--bordered {
    .AiInputWithSelector-inputResult {
      border: 1px solid colors.$gold-300;
    }
  }
}
</style>
